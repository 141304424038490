import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function InfoKey() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('iOS');

  // Retrieve deviceInfo from sessionStorage
  const storedDeviceInfo = sessionStorage.getItem('deviceInfo');
  const deviceInfo = JSON.parse(storedDeviceInfo);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleButtonClick = () => {
      navigate('/');
    };

    // Set the Main Button
    tg.MainButton.setText("Вернуться");
    tg.MainButton.show();
    tg.MainButton.onClick(handleButtonClick);

    // Show the Back Button
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/');
    });

    // Cleanup to prevent memory leaks
    return () => {
      tg.MainButton.offClick(handleButtonClick);
      tg.MainButton.hide();
      tg.BackButton.hide();
    };
  }, [navigate]);

  // Handle copying the appropriate link based on the device type
  const handleCopyKey = () => {
    const { link } = deviceInfo;  // Get the appropriate link from deviceInfo

    if (navigator.clipboard && link) {
      navigator.clipboard.writeText(link)
        .then(() => {
          handleButtonTextChange('Ссылка скопирована');
        })
        .catch(err => {
          console.error('Could not copy text:', err);
          fallbackCopyText(link);  // Fallback in case of error
        });
    } else {
      alert('Clipboard API not available or link is missing');
    }
  };

  // Fallback function for copying text
  const fallbackCopyText = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      handleButtonTextChange('ключ скопирован');
    } catch (err) {
      console.error('Fallback: Could not copy text:', err);
    }
    document.body.removeChild(textArea);
  };

  // Change button text temporarily after copying
  const handleButtonTextChange = (message) => {
    const copyButton = document.querySelector('.copy-button-text');
    copyButton.textContent = message;
    setTimeout(() => {
      copyButton.textContent = 'ваш ключ';
    }, 2000);
  };

  // Render instructions based on the device type
  const renderInstructions = () => {
    const deviceInfo = {
      iOS: {
        link: 'https://apps.apple.com/app/id6476628951',
        images: ['/manual_ios1.jpg', '/manual_ios2.jpg']
      },
      Android: {
        link: 'https://github.com/2dust/v2rayNG/releases/download/1.8.22/v2rayNG_1.8.22.apk',
        images: ['/manual_hiddify1.jpg', '/manual_hiddify2.jpg', '/manual_hiddify3.jpg']
      },
      Mac: {
        link: 'https://apps.apple.com/ru/app/foxray/id6448898396',
        images: ['/manual_ios1.jpg', '/manual_ios2.jpg']
      },
      Windows: {
        link: 'https://apps.microsoft.com/detail/9pdfnl3qv2s5?ocid=webpdpshare',
        images: ['/manual_hiddify1.jpg', '/manual_hiddify2.jpg', '/manual_hiddify3.jpg', '/manual_hiddify4.jpg']
      }
    };

    const { link, images } = deviceInfo[activeTab];

    switch (activeTab) {
      case 'iOS':
        return (
          <div>
            <h2 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '10px' }}>Инструкция для iPhone</h2>
            <div className='text' style={{ marginBottom: '10px' }}>
              1. Скачайте приложение <a style={{ textDecoration: 'none' }} href={link} target="_blank" rel="noopener noreferrer">V2RayTun</a>;<br />
              2. Скопируйте ваш ключ;<br />
              3. Вставьте ключ в приложении, как показано на картинке:
            </div>
            {images && images.map((img, index) => (
              <img key={index} style={{ width: '100%', marginBottom: '10px' }} src={img} alt={`Instruction ${index + 1}`} />
            ))}
          </div>
        );
      case 'Android':
        return (
          <div>
            <h2 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '10px' }}>Инструкция для Android</h2>
            <div className='text' style={{ marginBottom: '20px' }}>
              1. Скачайте приложение <a style={{ textDecoration: 'none' }} href={link} target="_blank" rel="noopener noreferrer">v2rayNG APK</a> или <a style={{ textDecoration: 'none' }} href={'https://play.google.com/store/apps/details?id=app.hiddify.com&pcampaignid=web_share'} target="_blank" rel="noopener noreferrer">Hiddify</a>;<br />
              2. Скопируйте ваш ключ;<br />
              3. Вставьте ключ в приложении, как показано на картинках:
            </div>
            {images && images.map((img, index) => (
              <img key={index} style={{ width: '100%', marginBottom: '10px' }} src={img} alt={`Instruction ${index + 1}`} />
            ))}
          </div>
        );
      case 'Mac':
        return (
          <div>
            <h2 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '10px' }}>Инструкция для Mac</h2>
            <div className='text' style={{ marginBottom: '20px' }}>
              1. Скачайте приложение <a style={{ textDecoration: 'none' }} href={link} target="_blank" rel="noopener noreferrer">FoxRay</a>;<br />
              2. Скопируйте ваш ключ;<br />
              3. Вставьте ключ в приложении, как показано на картинке:
            </div>
            {images && images.map((img, index) => (
              <img key={index} style={{ width: '100%', marginBottom: '10px' }} src={img} alt={`Instruction ${index + 1}`} />
            ))}
          </div>
        );
      case 'Windows':
        return (
          <div>
            <h2 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '10px' }}>Инструкция для Windows</h2>
            <div className='text' style={{ marginBottom: '20px' }}>
              1. Скачайте приложение <a style={{ textDecoration: 'none' }} href={link} target="_blank" rel="noopener noreferrer">Hiddify</a>;<br />
              2. Скопируйте ваш ключ;<br />
              3. Вставьте ключ в приложении.
            </div>
            {images && images.map((img, index) => (
              <img key={index} style={{ width: '100%', marginBottom: '10px' }} src={img} alt={`Instruction ${index + 1}`} />
            ))}
          </div>
        );
    }
  };

  return (
    <div className='wrapper'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h1 className='text' style={{ fontSize: '22px', textAlign: 'left', marginBottom: '20px' }}>
            Как пользоваться ключами
          </h1>

          {/* Tabs for device types */}
          <div className='tabs'>
            {['iOS', 'Android', 'Mac', 'Windows'].map((tab) => (
              <button
                key={tab}
                className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
                style={{ marginRight: '10px', padding: '10px', border: 'none', borderRadius: '10px' }}
              >
                {tab}
              </button>
            ))}
          </div>

          {/* Render instructions based on active tab */}
          {renderInstructions()}
        </>
      )}
    </div>
  );
}

export default InfoKey;
